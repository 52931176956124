import { DailyRequest } from "../../models/MissionModel";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import { PointServices } from "../../services/apiService/PointService";
import { userLocal } from "../../services/localService/localService";
import { useEffect, useState } from "react";
import { setAuthorization } from "../../services/apiService/configURL";
import PopupPoint from "../../components/Popup/PopupPoint";
import TITLE from "../../assets/image/Popup/title-coin.png";

export default function ItemMission({ res }: { res: DailyRequest }) {
  const { token } = userLocal.get();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isDisable, setIsDisable] = useState(res?.done);
  const [doingTimes, setDoingTimes] = useState<number>(res.doing_times);
  const [doneTimes, setDoneTimes] = useState<number>(res.done_times);
  const [totalPoint, setTotalPoint] = useState<number>(0);
  setAuthorization(token);
  useEffect(() => {}, []);
  const handleClickSubmit = () => {
    if (!isDisable) {
      console.log(res.quest_id);
      setIsShowPopup(true);
      PointServices.SubmitExchangePointAPI(res.quest_id)
        .then((res: any) => {
          setIsDisable(true);
          console.log(res);
          setTotalPoint(res.total_points);
          setDoneTimes(doneTimes + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleClose = () => {
    setIsShowPopup(false);
  };
  return (
    <div className="shadow-item mb-4 p-2 rounded-xl">
      <div className="mt-2 grid grid-cols-12 ">
        <div className="col-span-2">
          <img src={res.icon_url} />
        </div>
        <div className="col-span-6 text-[#2D4E68]">
          <div>{res.name}</div>
          <div>
            <div className="flex">
              <div className="col-span-2 flex items-center px-1">
                <img
                  src={ICON_COIN}
                  loading="lazy"
                  width={19}
                  height={19}
                  alt="Image"
                />
              </div>
              <div className="col-span-10">
                <div className="text-[#005CB4] flex items-center">
                  <div className="text-[20px] font-semibold-so text-[#2A2A2A]">
                    {res.point}
                  </div>
                  <div className="text-[11px] font-medium-so ml-1 mt-1 text-[#4E9ABB]">
                    Pepcoin
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 px-1">
          <button
            className={`${
              !isDisable
                ? res.login_quest
                  ? "bg-[#004B93]"
                  : "bg-[#59C2DD]"
                : "bg-[#bdbdbd]"
            } text-[#ffffff] text-[14px] w-full py-[8px] font-medium-so rounded-3xl shadow-item`}
            onClick={handleClickSubmit}
          >
            {res.login_quest ? "Nhận ngay" : "Thực hiện"}
          </button>
          <div className="text-[12px] text-[#818BA0] text-center mt-1">
            {doneTimes}/{doingTimes}
          </div>
        </div>
      </div>
      <PopupPoint isShow={isShowPopup}>
        <div className="relative z-50">
          <div className="w-full flex justify-center">
            <img src={TITLE} className="w-6/12" />
          </div>
          <div className="font-bold-so text-[20px] mt-3">
            Tích điểm thành công
          </div>
          <div className="text-[#219653] font-bold-so text-[20px]">
            +{res.point} Pepcoin
          </div>
          <div className="font-light-so text-[14px] text-[#636363] mt-5">
            Tổng số điểm tích được là
          </div>
          <div className="font-bold-so">{totalPoint} Pepcoin</div>
          <div className="mt-2">
            <button
              className="bg-[#59C2DD] text-white py-3 rounded-xl shadow-item px-14"
              onClick={handleClose}
            >
              Đồng ý
            </button>
          </div>
        </div>
      </PopupPoint>
    </div>
  );
}
