import BANNER from "../assets/image/Home/banner.png";
export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
  ],
};

export const listBanner = [
  {
    id: "1",
    img: BANNER,
    type: "spin",
  },
  {
    id: "2",
    img: BANNER,
    type: "gacha",
  },
  {
    id: "3",
    img: BANNER,
    type: "flipcard",
  },
  {
    id: "4",
    img: BANNER,
    type: "pickup",
  },
  {
    id: "5",
    img: BANNER,
    type: "spin",
  },
];
