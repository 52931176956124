import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import { useEffect, useState } from "react";
import { PointServices } from "../../services/apiService/PointService";
import { DailyRequest, LoginList } from "../../models/MissionModel";
import ItemMission from "./ItemMission";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";

export default function Mission() {
  const navigation = useNavigate();
  const { back } = useParams();
  const handleBack = () => {
    navigation(`${back ? "/" + back : "/"}`);
  };
  let { token } = userLocal.get();
  const [dailyRequest, setDailyRequest] = useState<[DailyRequest]>();
  const [loginList, setLoginList] = useState<[LoginList]>();
  setAuthorization(token);
  useEffect(() => {
    PointServices.ListExchangePointAPI()
      .then((res: any) => {
        setLoginList(res.login_list);
        const daily_quest_list = res.daily_quest_list.sort(sortByLoginQuest);
        setDailyRequest(daily_quest_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const sortByLoginQuest = (a: any, b: any) => {
    if (a.login_quest === b.login_quest) {
      return 0; // If both have the same value for login_quest, maintain their original order
    }
    return a.login_quest ? -1 : 1; // Place 'true' values before 'false' values
  };

  return (
    <div>
      <HeaderFrame
        footer=""
        title="Làm nhiệm vụ"
        handleBack={handleBack}
        iconBack={BACK}
      >
        <div className="mt-8 px-2 pt-2 pb-5 rounded-xl shadow-item mb-5">
          <div className="grid grid-cols-8 gap-2">
            {loginList?.map((res: LoginList, index: number) => {
              return (
                <div
                  className={
                    loginList.length - 1 === index
                      ? "col-span-2 flex flex-col justify-between"
                      : "col-span-1 flex flex-col justify-between"
                  }
                  key={index}
                >
                  <div
                    className={`${
                      !res.today
                        ? res.done
                          ? "bg-[#D4D4D4]"
                          : "bg-[#FFFFFF] shadow-item"
                        : "bg-[#59C2DD] shadow-item"
                    } flex flex-col justify-center h-[70px] items-center pt-1 pb-1 rounded-3xl mb-1`}
                  >
                    <div>
                      <img src={ICON_COIN} className="w-7" />
                    </div>
                    {/* <div className="text-[#818BA0] mt-1">+{res.point}</div> */}
                    <div
                      className={
                        loginList.length - 1 === index
                          ? "text-[#ffffff] rounded-2xl mt-2 bg-[#59C2DD] w-8/12 text-center relative z-50"
                          : `${
                              !res.today ? "text-[#818BA0]" : "text-[#FFFFFF]"
                            }  mt-1 text-[15px]`
                      }
                    >
                      {loginList.length - 1 === index ? (
                        <div className="w-4 h-4 bg-[#59C2DD] absolute left-1/2 -translate-x-1/2 rotate-45 -z-50 top-[-1px]"></div>
                      ) : null}
                      +{res.point}
                    </div>
                  </div>
                  <div
                    className={`${
                      res.today ? "text-[#59C2DD]" : "text-[#818BA0]"
                    } font-regular-so text-[10px] text-center`}
                  >
                    {res.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {dailyRequest?.map((res: DailyRequest, index: number) => (
          <ItemMission res={res} key={index} />
        ))}
      </HeaderFrame>
    </div>
  );
}
