import { useNavigate } from "react-router-dom";
import { ListLuckyDraw } from "../../models/ListRotation";
import { setAuthorization } from "../../services/apiService/configURL";
import { userLocal } from "../../services/localService/localService";

export default function ItemRotation({
  res,
  index,
}: {
  res: ListLuckyDraw;
  index: number;
}) {
  let { token } = userLocal.get();
  setAuthorization(token);
  const navigation = useNavigate();
  const handleGame = () => {
    if (!res.expired) {
      setAuthorization(token);
      navigation(`/${res.game_type}/${res.campaign_id}/${res.sale_id}`);
    }
  };
  return (
    <div
      className={`${res.expired ? "filter grayscale" : ""}
         grid grid-cols-12 gap-2 shadow-item border-rotation mt-3 px-2 py-2 rounded-2xl relative
      `}
      key={index}
      onClick={handleGame}
    >
      <div className="col-span-4">
        <img src={res.banner_url} alt="Image" className="rounded-xl w-full" />
      </div>
      <div className="col-span-8">
        <div className="font-bold-so text-[16px] text-reponsive">
          {res.campaign_name}
        </div>
        <div className="font-light-so text-[12px]">
          Bạn có{" "}
          <span className="text-[#ED3136] text-[17px] font-bold-so">
            {res.remaining_draw}
          </span>{" "}
          lượt chơi
        </div>
        <div className="text-[12px] font-light-so">
          Hết hạn{" "}
          <span className="text-[#ED3136] font-bold-so">
            {res.lucky_draw_expired_date}
          </span>
        </div>
      </div>
      <div className="absolute bottom-0 text-[14px] right-0 bg-[#92def1] pl-2 text-white rounded-tl-3xl rounded-br-2xl">
        <div className="bg-[#5BC2DC] py-[2px] px-3 rounded-tl-3xl rounded-br-2xl">
          {res.campaign_time}
        </div>
      </div>
    </div>
  );
}
