import { useNavigate } from "react-router-dom";
import { PrizeList } from "../../models/GiftModel";
import "../../assets/css/ListHistory.css";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import DOWN from "../../assets/image/ListGift/down.png";
import TORIGHT from "../../assets/image/ListGift/toright.png";
import { useState } from "react";
import QRCode from "react-qr-code";
import PopupLogin from "../../popup/PopupLogin";
export default function ItemGift({
  data,
  current,
  setIsPopup,
  setNoti,
  setIsDisableNavBar,
}: {
  data: PrizeList;
  current: string;
  setIsPopup: any;
  setNoti: any;
  setIsDisableNavBar: any;
}) {
  console.log(data);
  const [isOpenItem, setIsOpenItem] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigation = useNavigate();
  const handleRedirect = () => {
    if (data?.gift_type === "code") {
    } else {
      if (
        current === "1" &&
        data?.migration_data &&
        (data?.exchange_status === "used" || data?.exchange_status === "expire")
      ) {
        console.log(1);
      } else if (
        current === "1" &&
        data?.migration_data &&
        data?.exchange_status === "done"
      ) {
        const dataDetails = {
          type_id: data?.history_id ? "history_id" : "gift_line_id",
          id: data.history_id ? data.history_id : (data.gift_line_id as any),
        };
        ListReceiptServices.GetDetailsGift(dataDetails)
          .then((res: any) => {
            console.log(res);
          })
          .catch((err) => {
            setIsPopup(true);
            setNoti(err);
            console.log(err);
          });
        console.log(2);
      } else {
        if (!data?.is_top_up) {
          navigation(
            `/gift-details/${data.history_id ? "history_id" : "gift_line_id"}/${
              data.history_id ? data.history_id : data.gift_line_id
            }/${current}`
          );
        }
      }
    }
  };
  const hanldeClickShow = () => {
    setIsOpenItem(!isOpenItem);
  };
  const handleOpenPopup = () => {
    setIsDisableNavBar(!isOpenPopup);
    setIsOpenPopup(!isOpenPopup);
  };
  return (
    <>
      <div
        className={`
          ${
            current === "1" &&
            data?.migration_data &&
            data?.exchange_status === "done"
              ? ""
              : data?.exchange_status === "not_yet"
              ? ""
              : data?.exchange_status === "used"
              ? "filter grayscale"
              : data?.exchange_status === "expire"
              ? "filter grayscale"
              : ""
          } 
        rounded-2xl shadow-item mb-5 overflow-hidden`}
        onClick={handleRedirect}
      >
        <div className="grid grid-cols-12 gap-2 px-1 pt-3">
          <div className="col-span-4 flex justify-center items-center">
            <div className="w-full flex justify-center relative">
              <img
                src={data.gift_square_image_url}
                alt="Image"
                loading="lazy"
                className="w-10/12"
              />
              <div className="absolute bottom-0 text-white w-full z-10">
                <div className="bg-[#2D4E68] opacity-80 w-full absolute top-4 h-6 rounded-b-xl z-0"></div>
                <div className="relative z-20 text-center h-full top-4">
                  {data.reward_source}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-8 flex flex-col justify-between">
            <div className="text-[#2A2A2A] text-[20px] font-semibold-so">
              <div className="leading-6 text-reponsive text-[18px]">
                {data.gift_name}
              </div>
              <div className="text-[14px] text-[#4E9ABB] text-reponsive">
                {data.campaign_name}
              </div>
              <div className="text-[#4E9ABB] text-[14px] font-regular-so">
                <span className="text-[#2A2A2A] font-light-so">
                  Ngày trúng thưởng:&nbsp;
                </span>
                {data.successfully_date}
              </div>
            </div>
          </div>
        </div>
        {isOpenItem && (
          <div className="ml-2 mt-4">
            {/* <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "80px" }}
              value={data?.gift_code}
              viewBox={`0 0 256 256`}
            /> */}
            <div className="text-[#4E9ABB] text-[16px] font-regular-so">
              <span className="text-[#242E49] font-light-so">
                Gift Code:&nbsp;
              </span>
              {data?.gift_code}
            </div>
            <div className="text-[#4E9ABB] text-[16px] font-regular-so">
              <span className="text-[#242E49] font-light-so">
                Ngày hết hạn:&nbsp;
              </span>
              {data?.expired_date}
            </div>
            <div
              onClick={handleOpenPopup}
              className="bg-[#4E9ABB] mt-2 w-36 leading-5 h-14 text-white rounded-xl px-3 py-1 flex items-center"
            >
              <div className=" ">
                Hướng dẫn
                <br /> đổi quà
              </div>
              <div className="ml-3">
                <img src={TORIGHT} />
              </div>
            </div>
          </div>
        )}
        {current === "2" ? (
          <div className="flex justify-end mt-1">
            <button
              className={`${
                data.exchange_status === "done"
                  ? "bg-[#F2C94C] border-button-gift"
                  : "bg-[#81BEB4] border-notyet-gift"
              } relative rounded-tl-2xl text-[13px] w-40 right-auto py-1`}
              onClick={hanldeClickShow}
            >
              <div className="flex justify-center">
                {data.exchange_status === "done" ? "Đã nhận" : "Chưa nhận"}
                {data?.gift_type === "code" && (
                  <span className="ml-8 mt-1">
                    <img src={DOWN} />
                  </span>
                )}
              </div>
            </button>
          </div>
        ) : (
          <div className="flex justify-end mt-1">
            {current === "1" ? (
              <button
                onClick={hanldeClickShow}
                className={`bg-[#59C2DD] border-notyet-gift h-7 relative rounded-tl-2xl text-[13px] w-40 right-auto py-1`}
              >
                <div className="flex justify-center">
                  {data?.exchange_status === "done"
                    ? "Đã nhận"
                    : data?.exchange_status === "not_yet"
                    ? "Chưa nhận"
                    : data?.exchange_status === "used"
                    ? "Đã dùng"
                    : data?.exchange_status === "expire"
                    ? "Hết hạn"
                    : ""}
                  {data?.gift_type === "code" && (
                    <span className="ml-8 mt-1">
                      <img src={DOWN} />
                    </span>
                  )}
                </div>
              </button>
            ) : null}
          </div>
        )}
      </div>
      <PopupLogin isShow={isOpenPopup}>
        <div className="uppercase text-[#2D4E68] text-[24px] pt-3 font-semibold-so">
          Thông báo
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.conditions as string,
          }}
          className="text-[16px] p-3 font-regular-so text-[#4F4F4F]"
        ></div>
        <div>
          <button
            className="w-36 py-3 mb-3 leading-5 rounded-xl bg-[#5BC2DC] px-2 text-white"
            onClick={handleOpenPopup}
          >
            Đóng
          </button>
        </div>
      </PopupLogin>
    </>
  );
}
