import { useNavigate } from "react-router-dom";
import "../../assets/css/GameExchange.css";
import { ListBanner } from "../../models/HomePage";

export default function ItemGameExchangePoint({
  id,
  img,
  type,
  campaign_id,
  name,
  campaign_time,
}: ListBanner) {
  const navigation = useNavigate();
  const handlePlay = () => {
    navigation(`/prize-game/${id}`);
  };
  return (
    <div
      onClick={handlePlay}
      className="mb-4 mt-3 item-gift rounded-3xl overflow-hidden bg-[#EEF4F6]"
    >
      <div>
        <img src={img} loading="lazy" className="w-full rounded-b-2xl" />
      </div>
      <div className="relative">
        <div className="px-5 py-1 text-[20px] relative h-[60px] text-reponsive font-semibold-so text-reponsive">
          {name}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className=" px-5 py-1 bg-[#5BC2DC] border-play 
        rounded-tl-3xl font-light-so text-white relative right-0 bottom-0"
        >
          {campaign_time}
        </button>
      </div>
    </div>
  );
}
