import { Fragment } from "react/jsx-runtime";
import ChooseHome from "../../../components/NavChoosePageHome/ChooseHome";
import ICON_GIFT from "../../../assets/image/Home/icon-gift.png";
import { ListChannels } from "../../../models/HomePage";
import ItemCampaign from "./ItemCampaign";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Campaign({
  lstExchangeGift,
}: {
  lstExchangeGift: any;
}) {
  return (
    <div className="px-5">
      <div className="mt-3">
        <ChooseHome
          value="Xem tất cả"
          icon={ICON_GIFT}
          name="CHUỖI cửa hàng"
          navigate="/list-game/chanel_id"
        />
      </div>
      {lstExchangeGift?.channels && (
        <Carousel
          additionalTransfrom={0}
          autoPlaySpeed={3000}
          centerMode={false}
          className="pb-1 mt-2"
          containerClass="container-with-dots"
          dotListClass=""
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 2.2,
              partialVisibilityGutter: 50,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          arrows={false}
        >
          {lstExchangeGift?.channels?.map((res: ListChannels) => (
            <div className="" key={res.channel_id}>
              <ItemCampaign
                channel_id={res?.channel_id}
                channel_image_url={res?.channel_image_url}
                channel_name={res?.channel_name}
                channel_code={res?.channel_code}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}
