import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import {
  BACK_GLOBAL,
  userLocal,
} from "../../services/localService/localService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { setAuthorization } from "../../services/apiService/configURL";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import ICONPHONE from "../../assets/image/Popup/icon-phone.png";
import PopupTerm from "../../components/Popup/PopupTerm";

export default function Login() {
  const navigation = useNavigate();
  const [isShowPopup, setShowPopup] = useState(false);
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  console.log(data);

  const a = localStorage.getItem(BACK_GLOBAL);
  const handleBack = () => {
    navigation(`/`);
  };
  let { token } = userLocal.get();
  setAuthorization(token);
  useEffect(() => {
    if (token) {
      navigation("/");
    }
  }, []);
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data: any) => {
    console.log(data);
    setIsLoading(true);
    LoginServices.ValidateAPI(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("PHONE", data.phone);
        if (res.redirect_login) {
          navigation(`/validate-password`);
        } else {
          navigation(`/validate-otp/register/login`);
        }
        userLocal.set(res);
      })
      .catch((err) => {
        setIsError(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const checkPrize = () => {
    setShowPopup(true);
  };

  return (
    <div className="">
      <HeaderFrame
        footer={FOOTER}
        title="ĐĂNG KÝ/ ĐĂNG NHẬP"
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-10 px-3">
          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <label className="text-[#5BC2DC] font-semibold-so">
              Số điện thoại
            </label>
            <div className="rounded-lg bg-white mt-1 border-itemlogin">
              <input
                type="tel"
                placeholder="Vui lòng nhập số điện thoại của bạn"
                className="w-full px-5 rounded-2xl h-12 font-light-so text-[14px] input-hidden text-left"
                {...register("phone", {
                  required: "Không được để trống",
                  pattern: {
                    value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                    message: "Vui lòng nhập đúng số điện thoại",
                  },
                })}
              />
            </div>
            <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p
                          key={type}
                          className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                        >
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
            <div>
              <div className="mt-3">
                <div>
                  <input
                    type="checkbox"
                    placeholder=""
                    {...register("consent", {
                      required: "Vui lòng đồng ý điều khoản",
                    })}
                  ></input>
                  <span className="font-light-so text-[14px] ml-1">
                    Tôi đã đọc{" "}
                    <span
                      className="font-medium-so text-[#4E9ABB]"
                      onClick={() => checkPrize()}
                    >
                      {" "}
                      Điều khoản sử dụng
                    </span>{" "}
                    và đồng ý với nội dung này. 
                  </span>
                </div>
                <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                  <ErrorMessage
                    errors={errors}
                    name="consent"
                    render={({ messages }) => {
                      return messages
                        ? Object.entries(messages).map(([type, message]) => (
                            <p
                              key={type}
                              className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                            >
                              {message}
                            </p>
                          ))
                        : null;
                    }}
                  />
                </div>
              </div>
            </div>
            {isError ? (
              <div className="font-normal z-0 mt-3 text-[red] text-[13px] text-center">
                {isError}
              </div>
            ) : null}
            <div className="mt-10">
              <div className="mt-3 flex justify-center">
                {isLoading ? (
                  <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[15px] text-[14px] rounded-lg text-white">
                    <LoadingButton />
                  </div>
                ) : (
                  <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-4 text-[14px] rounded-lg text-white">
                    Tiếp theo
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="mt-5 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline} </a>
            </span>
            để được hỗ trợ
          </div>
        </div>
        {/* <ContactPhoneZalo /> */}
      </HeaderFrame>
      <PopupTerm isShowPopup={isShowPopup} setShowPopup={setShowPopup} />
    </div>
  );
}
