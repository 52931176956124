import { HistoryBill, Reward } from "../../models/HistoryModel";
import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import REWARD from "../../assets/image/ListHistory/reward.png";
import BILL from "../../assets/image/ListHistory/bill-example.png";
import ICON_GIFT_COLOR from "../../assets/image/Home/icon-gifthome-color.png";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import { useState } from "react";
import CANCEL from "../../assets/image/Home/cancel-popup.png";

export default function DetailsHistory({
  bill,
  setPopup,
}: {
  bill: HistoryBill;
  setPopup: any;
}) {
  const handleClose = () => {
    setPopup(false);
  };
  const [isOpenImage, setIsOpenImage] = useState<boolean>(true);
  const handleOpenImage = () => {
    setIsOpenImage(!isOpenImage);
    setPopup(false);
  };

  return (
    <div>
      {isOpenImage ? (
        <div className="relative top-0">
          <img src={bill?.receipt_image_url} className="w-full" />
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2">
            <img src={CANCEL} onClick={handleOpenImage} />
          </div>
        </div>
      ) : (
        <div className="w-full h-20 bg-[#5BC2DC] relative">
          <img src={HEADER_DETAILS} />
          <div className="absolute top-5 bg-white w-full rounded-t-2xl px-8">
            <div className="flex justify-center py-10">
              <img src={REWARD} />
            </div>
            <div className="text-[#333333] font-light-so text-[14px]">
              {bill?.rewards?.map((res: Reward, index: number) => (
                <div
                  className={`${index !== 0 && "border-reward-bill pt-5"} mb-5`}
                >
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">Chương trình</div>
                    <div className="text-right col-span-9 font-semibold-so">
                      {res?.campaign_name}
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">Lượt chơi</div>
                    <div className="text-right col-span-9 font-semibold-so">
                      +{res?.lucky_draw}
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">Số quà</div>
                    <div className="text-right col-span-9 font-semibold-so flex justify-end">
                      +{res?.gift_count}
                      <span className="ml-1">
                        <img src={ICON_GIFT_COLOR} className="w-5" />
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">Pepcoin</div>
                    <div className="text-right col-span-9 font-semibold-so flex justify-end">
                      +{res?.point}
                      <span className="ml-1">
                        <img src={ICON_COIN} className="w-5" />
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-4">Mã dự thưởng</div>
                    <div className="text-right col-span-8 font-semibold-so">
                      {res?.codes?.join(", ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="">
              <div className="border-reward-bill">
                <div
                  className="mt-3 grid grid-cols-12 gap-2 bg-[#EEEEEE] p-2 rounded-xl"
                  onClick={handleOpenImage}
                >
                  <div className="col-span-2">
                    <img src={BILL} />
                  </div>
                  <div className="col-span-10 leading-4">
                    <div className="text-[15px]">{bill?.store_name}</div>
                    <div className="text-[12px] font-light-so text-[#636363]">
                      Mã Hóa đơn |{" "}
                      <span className="text-[#2D4E68] font-medium-so">
                        {bill?.receipt_no}
                      </span>
                    </div>
                    <div className="text-[12px] font-light-so text-[#636363]">
                      {bill?.order_date}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-[14px] text-[#333333] font-light-so">
                <div className="flex justify-between">
                  <div>Trạng thái</div>
                  <div
                    className={`${
                      (bill?.state === "done" && "text-[#219653]") ||
                      (bill?.state === "fail" && "text-[#ED3136]")
                    } font-semibold-so`}
                  >
                    {bill?.status}
                  </div>
                </div>
                <div>
                  {bill?.state === "fail" && (
                    <div>
                      Lý do:
                      {bill?.invalid_reason?.map((res: string) => (
                        <div className="font-semibold-so">- {res}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="h-40"></div>
              <div className="flex justify-center">
                <button
                  className="fixed bottom-10 bg-[#5BC2DC] text-white py-4 rounded-xl w-6/12 shadow-item"
                  onClick={handleClose}
                >
                  Đồng ý
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
