import { useNavigate } from "react-router-dom";
import BG from "../../../assets/image/Popup/header.png";
import PLAY from "../../../assets/image/Popup/play.png";
import ANIMATE from "../../../assets/image/Popup/animate-bg.png";
import COIN from "../../../assets/image/coin.png";
import CODE from "../../../assets/image/Popup/code.png";
import { ListReceipt } from "../../../models/GameModel";
import { setAuthorization } from "../../../services/apiService/configURL";
import { homeServices } from "../../../services/apiService/homeService";
import {
  gameLocal,
  userLocal,
} from "../../../services/localService/localService";
import { Fragment } from "react/jsx-runtime";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";
import { useEffect, useState } from "react";
import LoadingButton from "../../../components/Loading/LoadingButton";

export default function PopupConfirm({
  isShow,
  listReceipt,
  setConfirm,
}: {
  isShow: boolean;
  listReceipt: ListReceipt;
  setConfirm: any;
}) {
  useEffect(() => {
    if (isShow) {
      localStorage.setItem("STATUS_CONFIRM", "TRUE");
    }
  }, [isShow]);
  let { token } = userLocal.get();
  setAuthorization(token);
  document.body.style.overflow = "unset";
  console.log(listReceipt);
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleConfirmGift = () => {
    setLoading(true);
    document.body.style.overflow = "unset";

    const params = {
      sale_id: listReceipt?.sale_id,
    };
    homeServices
      .customerConfirmApi(params)
      .then((res) => {
        console.log(res);
        console.log(listReceipt);
        if (listReceipt.rewards.length === 1) {
          console.log("hello");
          // getCalculator(
          //   listReceipt.sale_id,
          //   listReceipt.rewards[0].campaign_id
          // );
          gameLocal.set(listReceipt.rewards[0].game_data);
          const a = listReceipt.rewards[0].game_data;
          const campaign_id = listReceipt.rewards[0].campaign_id;
          const sale_id = listReceipt.sale_id;
          console.log(a);
          navigation(`/${a.game_type}/${campaign_id}/${sale_id}`);
          localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
          // navigation(`/list-gift/1`);
        } else {
          console.log("1");
          if (listReceipt.rewards.length === 0) {
            navigation("/exchange-point");
          } else {
            navigation("/games");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        localStorage.removeItem("SALE_ID");
        setConfirm(false);
        setLoading(false);
      });
  };
  const onRedirect = (game_id: string, campaign_id: number) => {
    const params = {
      sale_id: listReceipt?.sale_id,
    };
    console.log(`/${game_id}/${campaign_id}/${listReceipt?.sale_id}`);

    homeServices
      .customerConfirmApi(params)
      .then((res) => {
        console.log(res);

        navigation(`/${game_id}/${campaign_id}/${listReceipt?.sale_id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { hotline, zalo_link } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div className="relative w-full">
      {isShow ? (
        <div className="w-full min-h-full background-result">
          <div className="w-full">
            <img src={BG} className="w-full" />
            <div className="absolute top-40 z-0">
              <img src={ANIMATE} className="animate-bgresult rounded-full" />
            </div>
            <div className="relative w-full popupGift__background">
              <div className="min-h-[450px]">
                {listReceipt?.rewards?.map((res, index) => {
                  console.log(res);
                  return (
                    <div className="flex flex-col w-full justify-center items-center">
                      {res?.lucky_draw_count > 0 ? (
                        <>
                          <div className="border-img-point w-52 h-52 flex flex-col items-center justify-center">
                            <div className="flex justify-center items-center text-[#4E9ABB] font-bold-so text-[70px]">
                              +{res?.lucky_draw_count}
                              <div className="ml-1 flex items-center h-full">
                                <img src={PLAY} className="w-10 mt-4" />
                              </div>
                            </div>
                            <div className="-mt-5 text-[20px] text-center font-semibold-so">
                              lượt chơi
                            </div>
                          </div>
                          <div className=" font-semibold-so text-[20px] -mt-10 text-center">
                            <div className="text-[#243F94] uppercase text-[12px]">
                              Chương trình
                            </div>
                            <div className="text-[#333333] text-[20px] font-semibold-so">
                              {res?.campaign_name}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
                {listReceipt?.rewards?.map((res, index) => (
                  <Fragment>
                    <div key={index}>
                      {res.gifts?.map((item: any, indexItem) => (
                        <>
                          <div className="relative left-1/2 -translate-x-1/2 border-img-point w-56 h-56 flex flex-col justify-center items-center">
                            {item.quantity > 0 ? (
                              <>
                                <div className=" flex flex-col items-center justify-center">
                                  <div className="flex justify-center items-center text-[#4E9ABB] font-bold-so text-[60px]">
                                    +{item.quantity}
                                  </div>
                                  <div className="ml-1 flex items-center h-full">
                                    <img
                                      src={item?.gift_square_image_url}
                                      className="w-28 -mt-8"
                                    />
                                  </div>
                                </div>
                                <div className="-mt-3 text-center font-semibold-so">
                                  {item?.gift_name}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      ))}
                    </div>
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.point !== 0 && (
                      <>
                        <div className="flex mt-2 justify-center items-center text-[#4E9ABB] font-bold-so text-[80px]">
                          +{res.point}
                          <div className="ml-1">
                            <img src={COIN} className=" mt-4" />
                          </div>
                        </div>
                        <div className="text-white text-center -mt-5 font-semibold-so text-[20px]">
                          Pepcoin
                          <div className="text-[#4E9ABB] uppercase text-[12px]">
                            Chương trình
                          </div>
                          <div className="text-[#ffffff] text-[20px]">
                            {res?.campaign_name}
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.lucky_code_count > 0 && (
                      <>
                        <div className="flex -mt-5 flex-col w-full justify-center items-center">
                          <div className="border-img-point w-52 h-52 flex flex-col items-center justify-center">
                            <div className="flex justify-center items-center text-[#4E9ABB] font-bold-so text-[70px]">
                              +{res?.lucky_code_count}
                              <div className="ml-1 flex items-center h-full">
                                <img
                                  src={CODE}
                                  className="w-10 mt-4 rounded-full"
                                />
                              </div>
                            </div>
                            <div className="-mt-5 text-center font-semibold-so text-[20px]">
                              Mã dự thưởng
                            </div>
                          </div>
                          <div className=" font-semibold-so text-[20px] -mt-10 text-center">
                            <div className="text-[#243F94] uppercase text-[12px]">
                              Chương trình
                            </div>
                            <div className="text-[#333333] text-[20px]">
                              {res?.campaign_name}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.base_point !== 0 && (
                  <>
                    <div className="flex -mt-5 flex-col w-full justify-center items-center">
                      <div className="border-img-point w-52 h-52 flex flex-col items-center justify-center">
                        {(listReceipt?.rewards?.length as number) !== 0 && (
                          <div className="top-1 font-light-italic-so relative ">
                            Và tích lũy thêm
                          </div>
                        )}{" "}
                        <div className="flex justify-center items-center text-[#4E9ABB] font-bold-so text-[70px]">
                          +{listReceipt.base_point}
                          <div className="ml-1 flex items-center h-full">
                            <img
                              src={COIN}
                              className="w-10 mt-4 rounded-full"
                            />
                          </div>
                        </div>
                        <div className="-mt-5 text-center font-semibold-so text-[20px]">
                          Pepcoin
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="h-40 w-full bg-[#b5e9f7]"></div>
            <div className="fixed w-full bottom-0 h-24 bg-[#b5e9f7]">
              <div className="flex justify-center px-5 w-full z-30">
                {isLoading ? (
                  <button className="text-white bg-[#4E9ABB] w-9/12 py-4 rounded-xl border-code-gacha mt-0">
                    <LoadingButton />
                  </button>
                ) : (
                  <button
                    onClick={handleConfirmGift}
                    className="text-white bg-[#4E9ABB] w-9/12 py-1 rounded-xl border-code-gacha mt-0"
                  >
                    TIẾP TỤC
                    <br />
                    Xác nhận đủ quà/lượt chơi
                  </button>
                )}
              </div>
              <div className="text-black font-light-so text-[12px] w-full text-center mt-3 z-20">
                {" "}
                Gọi điện cho Hotline{" "}
                <a href={`tel:${hotline}`} className="text-[#2D4E68]">
                  {" "}
                  {hotline}
                </a>{" "}
                để được hỗ trợ
              </div>
            </div>
            <ContactPhoneZalo />
          </div>
        </div>
      ) : null}
    </div>
  );
}
