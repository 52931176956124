import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import BILL from "../../assets/image/ListHistory/bill-example.png";
import { useState } from "react";
import CANCEL from "../../assets/image/Home/cancel-popup.png";
import TITLE from "../../assets/image/Popup/title-coin.png";

export default function DetailsHistoryCoin({
  bill,
  setPopup,
}: {
  bill: any;
  setPopup: any;
}) {
  console.log(bill);
  const handleClose = () => {
    setPopup(false);
  };
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const handleOpenImage = () => {
    setIsOpenImage(!isOpenImage);
  };

  return (
    <div>
      {isOpenImage ? (
        <div className="relative top-0">
          <img src={bill?.receipt_image_url} className="w-full" />
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2">
            <img src={CANCEL} onClick={handleOpenImage} />
          </div>
        </div>
      ) : (
        <div className="w-full h-20 bg-[#5BC2DC] relative">
          <img src={HEADER_DETAILS} />
          <div className="absolute top-5 bg-white w-full rounded-t-2xl px-8">
            <div className="flex justify-center pt-10">
              <img src={TITLE} className="w-44" />
            </div>
            <div className="flex flex-col items-center">
              <div className="text-[24px] font-semibold-so">
                Tích điểm thành công
              </div>
              <div className="font-semibold-so text-[#219653] text-[24px]">
                +{bill?.points} Pepcoin
              </div>
              <div className="font-light-so my-2">
                Hóa đơn |{" "}
                <span className="text-[#2D4E68] font-regular-so">
                  {bill?.receipt_no}
                </span>
              </div>
            </div>
            <div className="">
              <div className="border-reward-bill">
                <div
                  className="mt-3 grid grid-cols-12 gap-2 bg-[#EEEEEE] p-2 rounded-xl"
                  onClick={handleOpenImage}
                >
                  <div className="col-span-2">
                    <img src={BILL} />
                  </div>
                  <div className="col-span-10 leading-4">
                    <div className="text-[15px]">{bill?.store_name}</div>
                    <div className="text-[12px] font-light-so text-[#636363]">
                      Mã Hóa đơn |{" "}
                      <span className="text-[#2D4E68] font-medium-so">
                        {bill?.receipt_no}
                      </span>
                    </div>
                    <div className="text-[12px] font-light-so text-[#636363]">
                      {bill?.order_date}
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-40"></div>
              <div className="flex justify-center">
                <button
                  className="fixed bottom-10 bg-[#5BC2DC] text-white py-4 rounded-xl w-6/12 shadow-item"
                  onClick={handleClose}
                >
                  Đồng ý
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
