import "../../assets/css/ListHistory.css";
import ICON_COIN from "../../assets/image/Home/coin.png";
import { HistoryPepcoin } from "../../models/HistoryModel";

export default function ItemHistoryCoin({ res }: { res: HistoryPepcoin }) {
  // console.log(res);
  return (
    <div className="rounded-2xl shadow-item mb-5">
      <div className="grid grid-cols-12 px-1 py-3">
        <div className="col-span-2 flex justify-center items-center">
          <div className="w-full flex justify-center">
            <img
              src={res?.daily ? ICON_COIN : res?.image_url}
              loading="lazy"
              className="w-14 h-14 object-cover rounded-lg"
            />
          </div>
        </div>
        <div className="col-span-8 flex flex-col justify-between px-1">
          <div className="text-[#2D4E68] text-[14px] font-medium-so text-reponsive">
            {res?.display_name}
          </div>
          <div className="font-light-so text-[12px]">{res?.date}</div>
        </div>
        <div
          className={`${
            res?.points > 0 ? "text-[#219653]" : "text-[#ED3136]"
          } col-span-2 `}
        >
          <div className="font-semibold-so text-[20px] text-center">
            {res?.points > 0 ? "+" + res?.points : res?.points}
          </div>
          <div className="text-[12px] font-light-so -mt-1">Pepcoin</div>
        </div>
      </div>
    </div>
  );
}
