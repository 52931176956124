import OverlayPopup from "../OverlayPopup/OverlayPopup";
import { GiftWheelModel } from "../../models/GiftModel";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import LoadingButton from "../Loading/LoadingButton";

export default function PopupReceiveGift({
  isShow,
  handleAgree,
  gift,
  isLoadButton,
}: {
  gift: GiftWheelModel;
  isShow: boolean;
  handleAgree: () => void;
  isLoadButton?: boolean;
}) {
  const game_data = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  const luotquay = localStorage.getItem("LUOT_QUAY");
  const gift_wheel = JSON.parse(localStorage.getItem("LUCKY_CODE") || "{}");
  console.log(gift);
  return (
    <div>
      <OverlayPopup isShow={isShow}>
        <div className="relative px-2 -top-10">
          <img
            src={game_data?.prize_header_image_url}
            className="relative px-10 z-10 infinite translate-y-[40%]"
            width={"100%"}
          />

          <div
            className="relative top-1/2 bg-white
            border-popupgift rounded-tl-[90px] rounded-tr-[90px] rounded-b-[68px]"
            style={{
              border: `9px solid #FFAE38
              
              `,
            }}
            // ${game_data?.button_border_color}
          >
            <div
              className="mt-8 font-semibold-so text-[21px] color-light-pepsi px-10"
              style={{ color: "#FFAE38" }}
            >
              CHÚC MỪNG BẠN ĐÃ TRÚNG
            </div>
            <div className="text-[16px] font-medium-so mt-2 text-[#333333]">
              {gift?.gift_name}
            </div>
            <div className="text-[#333333] text-[8px]">
              {gift_wheel?.exchange_code}
            </div>
            <div className="flex justify-center py-2">
              <img src={gift?.gift_reward_image_url} className="w-6/12" />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: gift?.conditions_receiving_prize,
              }}
              className="text-[11px] px-3 font-regular-so text-[#4F4F4F]"
            ></div>
            {gift_wheel?.show_lucky_code && (
              <div className="text-white font-medium-so text-[16px] py-1 border-code-gacha mt-2 border-code w-11/12 relative left-1/2 -translate-x-1/2">
                <div className="text-[#3D4966] font-regular-so ">
                  {gift_wheel?.lucky_program_content}
                </div>
                <div className="relative w-full mt-1">
                  {/* <img
                      src={CODE}
                      className="relative left-1/2 -translate-x-1/2 mt-1"
                    /> */}
                  <div className="flex justify-center text-[#2D4E68] py-1 mb-2 font-semibold-so text-[20px]">
                    <div className="w-36 border-code-wheel flex justify-center py-[2px] rounded-lg shadow-item">
                      <div className="w-[126px] py-1 rounded-lg bg-white">
                        {gift_wheel?.lucky_code}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font-regular-so text-[10px] text-black">
                  Vui lòng giữ Mã dự thưởng để tham gia Vòng quay vào cuối
                  chương trình.
                </div>
              </div>
            )}
            <button
              className="px-10 text-[16px] text-white bg-[#5BC2DC] my-3 py-2 rounded-full uppercase"
              style={{
                backgroundColor: game_data?.button_color,
                color: game_data?.button_text_color,
              }}
              onClick={handleAgree}
            >
              {isLoadButton ? (
                <div className="px-[76px] py-2">
                  <LoadingButton />
                </div>
              ) : (
                <>
                  {luotquay !== "0" ? (
                    <>
                      Bạn còn{" "}
                      <span className="text-[24px] font-bold-so">
                        {luotquay}
                      </span>{" "}
                      lượt quay
                    </>
                  ) : (
                    "Đồng ý"
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </OverlayPopup>
      <ContactPhoneZalo />
    </div>
  );
}
