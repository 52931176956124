import { useEffect, useState } from "react";
import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import { format } from "date-fns";
import { Calendar } from "phosphor-react";
import { vi } from "date-fns/locale";
import CALENDAR from "../../assets/image/ListHistory/calendar.png";
import {
  Button,
  DatePicker,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "keep-react";
import { formatDate } from "../../utils/formatData";

export default function HeaderFrame({
  handleBack,
  children,
  iconBack,
  title,
  footer,
  iconfilter,
  setStartDate,
  setEndDate,
}: {
  handleBack: () => void;
  children: React.ReactNode;
  iconBack: string;
  title: string;
  footer: string;
  iconfilter?: any;
  setStartDate?: any;
  setEndDate?: any;
}) {
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [selected, setSelected] = useState<any>(null);
  // const [startDate, setStartDate] = useState<string>("");
  // const [endDate, setEndDate] = useState<string>("");
  // console.log(startDate);
  // console.log(endDate);
  useEffect(() => {
    const start = formatDate(selected?.from);
    const end = formatDate(selected?.to);
    if (
      start !== "undefined-NaN-undefined" &&
      end !== "undefined-NaN-undefined"
    ) {
      setStartDate(start || "");
      setEndDate(end || "");
      // setShowDatePicker(false);
      setTimeout(() => {
        // setShowDatePicker(true);
        setShowDatePicker(false);
        // setSelected(null);
      }, 100);
    }
  }, [selected]);
  const handleApply = () => {
    // setSelectedRange(tempRange);
    console.log(1);

    setShowDatePicker(true);
  };
  return (
    <div>
      <div>
        <div>
          <div className="w-full h-20 bg-[#5BC2DC]">
            <img src={HEADER_DETAILS} />
          </div>
          <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] top-6 h-[96%] w-full z-10">
            <ul className="containerNotify__background-list pt-3 box-border relative z-30 px-3 h-full">
              <div className="relative z-20">
                <div>
                  <img
                    src={iconBack}
                    className="w-9 relative z-10"
                    onClick={handleBack}
                  />
                </div>
                <div
                  onClick={() => {
                    setShowDatePicker(true);
                    setShowDatePicker(true);
                  }}
                  className="absolute w-full -mt-1 text-center text-[20px] top-1 left-1/2 -translate-x-1/2 text-[#2D4E68] font-semibold-so"
                >
                  {iconfilter ? (
                    <Popover showArrow={false} placement="bottom-end">
                      <PopoverContent className="z-50 max-w-min">
                        {/* {showDatePicker && ( */}
                        <div>
                          <DatePicker
                            mode="range"
                            selected={selected}
                            onSelect={setSelected}
                            showOutsideDays={true}
                            classNames={{
                              day_hidden: "invisible",
                              months:
                                "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                              month: "space-y-4",
                              caption_label:
                                "text-body-3 font-semibold text-black dark:text-black",
                              nav: "space-x-1 flex items-center",
                              nav_button_next: "absolute right-1",
                              table: "w-full border-collapse space-y-1",
                              head_row:
                                "flex border-y border-metal-50 dark:border-metal-800",
                              head_cell:
                                "text-[#333333] dark:text-[#333333] rounded-xl w-10 font-normal text-body-4",
                              row: "flex w-full mt-2",
                              day_range_middle:
                                "aria-selected:bg-[#cff5ff] text-[#76DBF4] aria-selected:text-[#76DBF4] dark:aria-selected:bg-[#FF1400] ",

                              day_outside:
                                "day-outside text-[#76DBF4] aria-selected:text-[#FF1400] aria-selected:text-[#FF1400] dark:text-[#FF1400] dark:aria-selected:text-[#FF1400] dark:aria-selected:bg-[#5BC2DC]",

                              day_selected: "bg-[#333333] text-[#5BC2DC] ",
                            }}
                            className="text-[#76DBF4]"
                          />
                          <div className=" flex justify-end w-full my-1">
                            {/* <button
                              onClick={() => handleApply()}
                              className="bg-[#4E9ABB] relative right-3 px-3 py-1 rounded-md text-white"
                            >
                              Apply
                            </button> */}
                          </div>
                        </div>
                        {/* )} */}
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <Button
                          className="w-full -mt-2 flex justify-center text-center rounded-xl px-4 text-body-4 font-normal text-metal-600 "
                          onClick={() => {
                            setShowDatePicker(true);
                          }}
                        >
                          <div className=" absolute right-0 top-0">
                            <img src={CALENDAR}></img>
                          </div>
                          {selected ? (
                            <div className="text-[16px]">
                              {format(
                                selected?.from ?? new Date(),
                                "dd LLL y",
                                { locale: vi }
                              )}{" "}
                              -{" "}
                              {format(selected?.to ?? new Date(), "dd LLL y", {
                                locale: vi,
                              })}
                            </div>
                          ) : (
                            <span className="">Lịch sử</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                    </Popover>
                  ) : (
                    <div className="mt-1">{title}</div>
                  )}
                </div>
                <div className="w-9 absolute top-0 right-10 z-40">
                  {/* <img src={iconfilter} /> */}
                </div>
                <div>{children}</div>
              </div>
            </ul>
            <div className="fixed -bottom-6 z-30 overflow-hidden w-full">
              <img src={footer} className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
