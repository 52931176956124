import { useState, ChangeEvent } from "react";
import "../../assets/css/MenuTab.css";
interface Props {
  current: string;
  nameLeft: string;
  nameRight: string;
  handleClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    status_gifts: string
  ) => void;
  nameCenter: string;
}
export default function Menu3Tab({
  current,
  handleClick,
  nameLeft,
  nameRight,
  nameCenter,
}: Props) {
  return (
    <div className="relative top-0">
      <div>
        <div className="menu3tab bg-[#FFFFFF] flex justify-between items-center align-middle px-2">
          <div className="flex justify-center flex-auto">
            <button
              key="1"
              id={"1"}
              disabled={current === "1"}
              onClick={(e) => handleClick(e, "quotation")}
              className="font-semibold-so leading-4 mt-[8px] text-[11px] "
            >
              {nameLeft}
            </button>
          </div>
          <div className="flex justify-center flex-auto px-2">
            <button
              key="2"
              id={"2"}
              disabled={current === "2"}
              onClick={(e) => handleClick(e, "done")}
              className="font-semibold-so leading-4 mt-[8px] text-[12px]"
            >
              {" "}
              {nameCenter}
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="3"
              id={"3"}
              disabled={current === "3"}
              onClick={(e) => handleClick(e, "done")}
              className="font-semibold-so leading-4 mt-[8px] text-[12px]"
            >
              {" "}
              {nameRight}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
