import { Fragment } from "react/jsx-runtime";
import ChooseHome from "../../../components/NavChoosePageHome/ChooseHome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../../../utils/settingSlide";
import { ListBrands } from "../../../models/HomePage";
import ItemBrand from "./ItemBrand";
import ICON_DIAMOND from "../../../assets/image/Home/icon-diamond.png";

export default function Brand({ lstBrands }: { lstBrands: any }) {
  return (
    <div className="px-5">
      <div>
        <ChooseHome
          value={"Xem tất cả"}
          icon={ICON_DIAMOND}
          name="Thương hiệu"
          navigate="/brand"
        />
      </div>
      {lstBrands?.categories?.length < 7 ? (
        <div className="grid grid-cols-6 gap-5 mt-3 ">
          {lstBrands?.categories?.map((res: ListBrands, index: number) => (
            <div key={index}>
              {" "}
              <ItemBrand category_image_url={res?.category_image_url} />
            </div>
          ))}
        </div>
      ) : (
        <Fragment>
          <div className="mt-3 max-[430px]:w-[98%]">
            <Slider {...settings}>
              {lstBrands?.categories?.map((res: ListBrands) => (
                <Fragment key={res?.category_id}>
                  <ItemBrand
                    category_id={res?.category_id}
                    category_image_url={res?.category_image_url}
                  />
                </Fragment>
              ))}
            </Slider>
          </div>
        </Fragment>
      )}
    </div>
  );
}
