import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
} from "./utils/deviceModel";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ListHistory from "./pages/ListHistory/ListHistory";
import ListGift from "./pages/ListGift/ListGift";
import Profile from "./pages/Profile/Profile";
import ListBrand from "./pages/ListBrand/ListBrand";
import Gacha from "./pages/Game/Gacha/Gacha";
import SpinWheel from "./pages/Game/SpinWheel/SpinWheel";
import FlipCard from "./pages/Game/FlipCard/FlipCard";
import AccumulatePoints from "./pages/ListAccumulatePoints/AccumulatePoints";
import ListGameExchangePoint from "./pages/ListGameExchangePoint/ListGameExchangePoint";
import Pickup from "./pages/Game/Pickup/Pickup";
import PrizeGame from "./pages/HomePage/BannerGame/PrizeGame";
import Login from "./pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailsRating from "./pages/HomePage/DetailsRating/DetailsRating";
import PageGuide from "./pages/GuideTakeAPhoto/PageGuide";
import RulesPoint from "./pages/Rules/RulesPoint";
import ListReceiveCustomer from "./pages/ListReceiveCustomer/ListReceiveCustomer";
import ExchangePoint from "./pages/ListExchangePoint/ExchangePoint";
import Waiting from "./components/Popup/Waiting";
import ListRotation from "./pages/ListRotation/ListRotation";
import Mission from "./pages/Mission/Mission";
import DetailsNews from "./pages/HomePage/ItemTabHome/DeatailsNews";
import Flip from "./pages/Game/GamePri/Flip";
import { QueryClient, QueryClientProvider } from "react-query";
import DetailsExhangePoint from "./pages/ListExchangePoint/DetailsExchangePoint";
import Countdown from "./components/Popup/CountDown";
import ValidatePassword from "./pages/Login/ValidatePassword";
import ValidateOTP from "./pages/Login/ValidateOTP";
import Register from "./pages/Login/Register";
import ForgotPassword from "./pages/Login/ForgotPassword";
import UpdateProfile from "./pages/Login/UpdateProfile";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";
import GiftDetails from "./pages/ListGift/GiftDetails";
import { userLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import CarouselPhoto from "./pages/GuideTakeAPhoto/CarouselPhoto";
import UrgentPageReview from "./pages/GuideTakeAPhoto/UrgentPageReviewBill/UrgentPageReview";
import BillInValid from "./pages/GuideTakeAPhoto/UrgentPageReviewBill/BillInValid";
import NewGacha from "./pages/Game/NewGacha/NewGacha";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NoAccessBrowser from "./components/NoAccessBrowser/NoAccessBrowser";
import PopupConfirm from "./pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirm";
import PeerJumpTower from "./pages/Game/PeerJumpTower/PeerJumpTower";
import PeerReceive from "./pages/Game/PeerJumpTower/PeerReceive";
import ListLeaderBoard from "./pages/ListLeaderBoard/ListLeaderBoard";

function App() {
  let access = false;
  document.body.style.backgroundColor = "white";
  let mobileDevice = isMobileDevice();
  let supportedBrowser = isSupportedBrowser();
  if ((mobileDevice && supportedBrowser) || isLocalhost()) {
    console.log(window.screen.availWidth);
    access = true;
    if (window.screen.availWidth < 440) {
      access = true;
    }
  }
  let { token } = userLocal.get();
  setAuthorization(token);
  const queryClient = new QueryClient();
  useEffect(() => {
    console.log(mobileDevice);

    // if (mobileDevice) {
    //   const handleOrientationChange = () => {
    //     const isLandscape = window.innerHeight < window.innerWidth;
    //     if (isLandscape) {
    //       alert("Vui lòng xoay điện thoại về chế độ dọc để tiếp tục.");
    //     }
    //   };

    //   window.addEventListener("resize", handleOrientationChange);
    //   window.addEventListener("orientationchange", handleOrientationChange);

    //   return () => {
    //     window.removeEventListener("resize", handleOrientationChange);
    //     window.removeEventListener(
    //       "orientationchange",
    //       handleOrientationChange
    //     );
    //   };
    // }
  }, []);
  return (
    <div className="font-regular-so ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {access ? (
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login/:back?" element={<Login />} />
              <Route
                path="/validate-password/:back?"
                element={<ValidatePassword />}
              />
              <Route
                path="/validate-otp/:next/:back?"
                element={<ValidateOTP />}
              />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/tc" element={<RulesPoint />} />
              <Route path="/waiting" element={<Waiting />} />
              <Route path="/countdown" element={<Countdown />} />
              <Route
                path="/exchange-point/:back?"
                element={<ExchangePoint />}
              />
              <Route path="/mission/:back?" element={<Mission />} />
              <Route path="/list-gift/:current?" element={<ListGift />} />
              <Route
                path="/gift-details/:type_id/:id/:current"
                element={<GiftDetails />}
              />
              <Route path="/accumulate-point" element={<AccumulatePoints />} />
              <Route path="/list-history" element={<ListHistory />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/brand" element={<ListBrand />} />
              <Route
                path="/list-game/:type/:id?"
                element={<ListGameExchangePoint />}
              />
              <Route path="/games" element={<ListRotation />} />
              <Route
                path="/list-receive-customer/:type/:id?"
                element={<ListReceiveCustomer />}
              />
              <Route
                path="/list-leader-board/:type/:id?"
                element={<ListLeaderBoard />}
              />
              <Route path="/prize-game/:id" element={<PrizeGame />} />
              <Route
                path="/guide-take-photo/:id?"
                element={<GuideTakeAPhoto />}
              />
              <Route path="/urgent-page" element={<UrgentPageReview />} />
              <Route path="/bill-invalid" element={<BillInValid />} />
              <Route
                path="/guide-page-picture/:id?"
                element={<CarouselPhoto />}
              />
              {/* <Route path="/popup" element={<PopupConfirm />} /> */}

              <Route path="/details-rating" element={<DetailsRating />} />
              <Route path="/details-news/:id" element={<DetailsNews />} />
              <Route
                path="/details-gift/:id/:back?"
                element={<DetailsExhangePoint />}
              />
              <Route
                path="/ball_machine/:campaign_id/:sale_id"
                element={<NewGacha />}
              />
              <Route
                path="/ball_machines/:campaign_id/:sale_id"
                element={<Gacha />}
              />
              <Route
                path="/lucky_wheel/:campaign_id/:sale_id"
                element={<SpinWheel />}
              />
              <Route
                path="/card_flipping/:campaign_id/:sale_id"
                element={<FlipCard />}
              />
              <Route
                path="/card_flip_flop/:campaign_id/:sale_id"
                element={<Flip />}
              />
              <Route
                path="/pickup_machine/:campaign_id/:sale_id"
                element={<Pickup />}
              />
              <Route
                path="/bear_pickup/:campaign_id/:sale_id"
                element={<PeerJumpTower />}
              />
              <Route
                path="/peerreceive/:campaign_id/:sale_id"
                element={<PeerReceive />}
              />
            </Routes>
            {/* </CSSTransition>
            </TransitionGroup> */}
          </QueryClientProvider>
        </BrowserRouter>
      ) : (
        <NoAccessBrowser />
      )}
    </div>
  );
}

export default App;
