export default function ItemBall({
  play,
  ball,
  listDraw,
}: {
  play: Boolean;
  ball: any;
  listDraw: any;
}) {
  return (
    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full">
      <div className="relative px-12 w-full">
        <div className="grid grid-cols-2 gap-2 relative -bottom-28">
          <div className={play ? "flex justify-end fly1" : "flex justify-end"}>
            <div className="relative rotate1">
              <img src={ball[0]} loading="lazy" className="w-20" />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[0]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div
            className={play ? "flex justify-start fly2" : "flex justify-start"}
          >
            <div className="relative rotate2 left-4">
              <img
                src={ball[ball.length - 1]}
                className="w-20"
                loading="lazy"
              />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[1]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 relative -bottom-[75px] px-2">
          <div className={play ? "flex justify-end fly3" : "flex justify-end"}>
            <div className="relative rotate3">
              <img
                src={ball[ball.length - 1]}
                loading="lazy"
                className="w-20"
              />

              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[2]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={play ? "flex justify-end fly4" : "flex justify-end"}>
            <div className="relative rotate4">
              <img
                src={ball[ball.length - 2]}
                loading="lazy"
                className="w-20"
              />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[3]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={play ? "flex justify-end fly5" : "flex justify-end"}>
            <div className="relative rotate1">
              <img
                src={ball[ball.length - 1]}
                loading="lazy"
                className="w-20"
              />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[4]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 relative -bottom-10">
          <div
            className={
              play ? "flex justify-between fly11" : "flex justify-between"
            }
          >
            <div className="relative rotate3 left-1">
              <img src={ball[0]} loading="lazy" className="w-20" />

              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[5]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div
            className={
              play ? "flex justify-between fly6" : "flex justify-between"
            }
          >
            <div className="relative rotate2 left-1">
              <img src={ball[0]} loading="lazy" className="w-20" />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[5]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div
            className={play ? "flex justify-start fly7" : "flex justify-start"}
          >
            <div className="relative rotate3">
              <img
                src={ball[ball.length - 2]}
                loading="lazy"
                className="w-20"
              />

              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[6]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 relative bottom-0">
          <div className={play ? "flex justify-end fly8" : "flex justify-end"}>
            <div className="relative rotate4 left-8 bottom-1">
              <img
                src={ball[ball.length - 1]}
                loading="lazy"
                className="w-20"
              />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[7]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={play ? "flex justify-end fly9" : "flex justify-end"}>
            <div className="relative rotate1">
              <img
                src={ball[ball.length - 2]}
                loading="lazy"
                className="w-20"
              />

              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[8]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={play ? "flex justify-end fly10" : "flex justify-end"}>
            <div className="relative rotate2 right-8 bottom-4">
              <img
                src={ball[ball.length - 1]}
                loading="lazy"
                className="w-20"
              />
              <div className="absolute top-2 left-4">
                <img
                  src={listDraw[9]?.gift_square_image_url}
                  className="w-12 img-gift"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
