import { axiosInstance } from "./configURL";

export const PointServices = {
  ListExchangePointAPI: () => {
    return axiosInstance.get(`/daily_quest/info`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  SubmitExchangePointAPI: (quest_id: number) => {
    return axiosInstance.post(
      `/daily_quest/submit`,
      {
        params: { quest_id: quest_id },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
