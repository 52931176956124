import Navbar from "../../components/NavbarMenu/Navbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBannerHome, homeBanner } from "../../redux/slices/HomeSlice";
import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import AVA from "../../assets/image/ava-test.png";
import CHANGEPROFILE from "../../assets/image/Profile/changeprofile.png";
import EXCHANGE from "../../assets/image/Profile/exchangegift.png";
import LOGOUT from "../../assets/image/Profile/logout.png";
import MISSION from "../../assets/image/Profile/mission.png";
import ICONNEXT from "../../assets/image/Profile/iconnext.png";
import PopupLogin from "../../popup/PopupLogin";
import { AppDispatch } from "../../redux/stores/store";
import { userLocal } from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import { LoginServices } from "../../services/apiService/LoginService";
import { homeServices } from "../../services/apiService/homeService";
import { ProfileModel } from "../../models/LoginModel";
import ICON_COIN from "../../assets/image/GameExchangePoint/coin-profile.png";
import { MaskedPhoneNumber } from "../../utils/formatData";

export default function Profile() {
  const [isLogout, setIsLogout] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [profiles, setProfiles] = useState<ProfileModel>();
  let { token } = userLocal.get();
  setAuthorization(token);
  let appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  useEffect(() => {
    dispatch(getAllBannerHome());
    homeServices
      .GetProfile()
      .then((res: any) => {
        console.log(res);
        setProfiles(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const navigation = useNavigate();
  const handleBack = () => {
    navigation("/");
  };
  const handleExchange = () => {
    navigation("/exchange-point/profile");
  };
  const handleLogout = () => {
    setIsLogout(true);
  };
  const handleClose = () => {
    navigation("/");
    localStorage.clear();
  };
  const handleMission = () => {
    navigation("/list-gift/1");
  };
  const handleCancel = () => {
    setIsLogout(false);
  };
  const goToNext = () => {
    navigation("/update-profile");
  };

  return (
    <div className="">
      <div className="w-full h-20 bg-[#5BC2DC]"></div>
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] top-6 h-[96%] w-full z-10">
        <ul className="containerNotify__background-list pt-3 box-border z-30 px-3">
          <div className="flex items-center relative">
            <div onClick={handleBack}>
              <img src={BACK} className="w-9" />
            </div>
            <div className="absolute text-[20px] left-1/2 -translate-x-1/2 text-[#2D4E68] font-semibold-so">
              Tài khoản
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-full mt-10">
            <div className="w-full flex justify-center">
              <img src={AVA} className="w-28 rounded-full" />
            </div>
            <div className="font-bold-so text-[22px]">{profiles?.name}</div>
            <div className="text-[#8A8A8A]">
              {MaskedPhoneNumber(profiles?.phone)}
            </div>
            <div className="w-full">
              <div
                className="shadow-item w-full px-2 py-3 grid grid-cols-12 gap-2 rounded-2xl mt-3"
                onClick={goToNext}
              >
                <div className="col-span-2 flex items-center">
                  <img src={CHANGEPROFILE} />
                </div>
                <div className="col-span-9 flex items-center">
                  Thay đổi thông tin
                </div>
                <div className="col-span-1 flex items-center">
                  <img src={ICONNEXT} />
                </div>
              </div>
              <div
                className="shadow-item w-full px-2 py-3 grid grid-cols-12 gap-2 rounded-2xl mt-3"
                onClick={handleExchange}
              >
                <div className="col-span-2 flex items-center">
                  <img src={ICON_COIN} />
                </div>
                <div className="col-span-9 flex items-center">Đổi điểm</div>
                <div className="col-span-1 flex items-center">
                  <img src={ICONNEXT} />
                </div>
              </div>
              <div
                className="shadow-item w-full px-2 py-3 grid grid-cols-12 gap-2 rounded-2xl mt-3"
                onClick={handleMission}
              >
                <div className="col-span-2 flex items-center">
                  <img src={EXCHANGE} />
                </div>
                <div className="col-span-9 flex items-center">Quà của tôi</div>
                <div className="col-span-1 flex items-center">
                  <img src={ICONNEXT} />
                </div>
              </div>
              <div
                className="shadow-item w-full px-2 py-3 grid grid-cols-12 gap-2 rounded-2xl mt-3 mb-5"
                onClick={handleLogout}
              >
                <div className="col-span-2 flex items-center">
                  <img src={LOGOUT} />
                </div>
                <div className="col-span-9 flex items-center">Đăng xuất</div>
                <div className="col-span-1 flex items-center">
                  <img src={ICONNEXT} />
                </div>
              </div>
              <div className="h-32"></div>
            </div>
          </div>
        </ul>
      </div>
      <Navbar />
      <PopupLogin isShow={isLogout}>
        <div className="px-5 pb-5">
          <div>
            {" "}
            <div className="">
              <div className="text-[24px] font-semibold-so pt-5 uppercase">
                Đăng xuất
              </div>
            </div>
          </div>
          <div className="mt-5">
            Bạn muốn đăng xuất khỏi
            <br /> tài khoản này?
          </div>
          <div>
            <button
              onClick={handleCancel}
              className="bg-[#ffffff] border-cancel text-[#5BC2DC] w-32 shadow-item mr-8 mt-5 font-semibold-so py-3 text-[14px] rounded-lg"
            >
              Hủy
            </button>
            <button
              onClick={handleClose}
              className="bg-[#5BC2DC] w-32 shadow-item mt-5 font-semibold-so py-3 text-[14px] rounded-lg text-white"
            >
              Đồng ý
            </button>
          </div>
        </div>
      </PopupLogin>
    </div>
  );
}
