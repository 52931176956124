import "../../assets/css/Tc.css";
import BACK from "../../assets/image/Header/back-header.png";
import { useNavigate } from "react-router-dom";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import PopupLogin from "../../popup/PopupLogin";
import CLOSE from "../../assets/image/Home/cancel-popup.png";
import { useState } from "react";

export default function RulesPoint() {
  const data: any = useQuery({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const navigation = useNavigate();
  const handleBack = () => {
    document.body.style.overflow = "unset";
    navigation("/");
  };
  const [isShowPopup, setIsShowPopup] = useState(false);
  const handleOpenPopup = () => {
    setIsShowPopup(true);
  };
  const handleClose = () => {
    setIsShowPopup(false);
    document.body.style.overflow = "unset";
  };
  const handleJoin = () => {
    document.body.style.overflow = "unset";
    navigation("/guide-page-picture/0");
  };
  return (
    <div>
      <HeaderFrame
        footer=""
        title={"Thể lệ chương trình"}
        handleBack={handleBack}
        iconBack={BACK}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data ? data?.data?.loyalty_content : null,
          }}
          className="font-light-so mt-5 point-ul"
        ></div>
        <div className="h-20"></div>
      </HeaderFrame>
      <div className="flex flex-col items-center justify-center fixed bottom-0 w-full z-20">
        <div className="relative z-10 flex items-center justify-center w-11/12">
          <div
            className={`bg-[#5BC2DC] px-1 py-4 rounded-xl w-full text-center border-first shadow-item`}
            onClick={handleOpenPopup}
          >
            <button className="text-white ">Tham gia ngay</button>
          </div>
        </div>
        <div className="h-4 relative z-0 w-full bg-white"></div>
      </div>
      <PopupLogin isShow={isShowPopup}>
        <div className="py-5 px-5 text-center relative font-regular-so">
          <div className="uppercase text-[#2D4E68] text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="text-left">
            - Hình hóa đơn tham gia chương trình chỉ áp dụng trong vòng{" "}
            <span className="text-[#223F95] font-semibold-so">
              03 ngày kể từ thời điểm xuất hóa đơn.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được đổi quà bằng Pepcoin{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 3 lần/ngày.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được nhận được{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 500 Pepcoin/tháng.
            </span>
          </div>
        </div>
        <div className="pb-5">
          <button
            className="bg-[#5BC2DC] px-14 py-4 rounded-2xl shadow-item text-white"
            onClick={handleJoin}
          >
            Đồng ý
          </button>
        </div>
        <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
          <img src={CLOSE} onClick={handleClose} />
        </div>
      </PopupLogin>
      {/* <PopupLogin isShow={isShowPopup}>
        <div className="py-5">
          <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="py-5">
            Chờ đón nhiều Chương trình Khuyến Mãi siêu hot đang sắp diễn ra!
          </div>
          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupLogin> */}
    </div>
  );
}
