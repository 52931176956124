import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import LOGO from "../../assets/image/GameExchangePoint/logobrand.png";
import { Gifts } from "../../models/HomePage";
import { useNavigate } from "react-router-dom";

export default function ItemExchangePoint({
  gift_id,
  gift_name,
  required_points,
  gift_image_url,
  gift_vendor_logo_url,
  reward_id,
  reward_image_url,
}: Gifts) {
  const navigation = useNavigate();
  const handleRedirect = (id: number) => {
    navigation(`/details-gift/${id}/exchange-point`);
  };
  return (
    <div className="col-span-1" onClick={() => handleRedirect(reward_id)}>
      <div className="rounded-2xl mt-3 mr-2">
        <div className="bg-[#F2FCFF] rounded-t-2xl">
          <div className="flex justify-center">
            <img
              src={reward_image_url}
              loading="lazy"
              className="w-full rounded-2xl min-h-[100px]"
              alt="Image"
            />
          </div>
        </div>
        <div className="bg-[#F2FCFF] rounded-b-2xl px-1 pb-2 relative">
          <div className="pt-2 w-9 pl-1">
            <img src={gift_vendor_logo_url} />
          </div>
          <div className="font-semobold-so pl-1 text-reponsive min-h-8">
            {gift_name}
          </div>
          <div className="flex items-start">
            <div className="col-span-2">
              <img
                src={ICON_COIN}
                loading="lazy"
                width={15}
                height={19}
                alt="Image"
                className="mt-2"
              />
            </div>
            <div className="col-span-10 pl-1">
              <div className="text-[#4E9ABB] ">
                <div className="text-[20px] font-semibold-so h-6  flex items-start ">
                  {required_points}
                </div>
                <div className="text-[11px] font-light-so text-[#97A2B0]">
                  Pepcoin
                </div>
              </div>
            </div>
          </div>
          <div className="absolute font-medium-so bottom-0 right-0 text-white bg-[#59C2DD] px-3 border-change rounded-tl-2xl rounded-br-2xl">
            Đổi ngay
          </div>
        </div>
      </div>
    </div>
  );
}
