import BG_POPUP_FLIPCARD from "../../../assets/image/Game/FlipFlop/popuplb.png";
import CLOSE from "../../../assets/image/Game/FlipFlop/close.png";
import { Fragment, useEffect, useState } from "react";
import Card from "./Card";
import "./styles.scss";
import GameOver from "./GameOver";
import BUTTON from "../../../assets/image/Game/FlipCard/button.png";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import { GameServices } from "../../../services/apiService/GameService";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import { useNavigate, useParams } from "react-router-dom";
import { setAuthorization } from "../../../services/apiService/configURL";
import PopupError from "../../../components/Popup/PopupError";

const GameBoard = () => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState<string>("no");
  const cards = [
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1718787931752.png",
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1718787931752.png",
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1714705291866.png",
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1714705291866.png",
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1719809151867.png",
    "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GIFT/Trung-Tran_1719809151867.png",
  ];
  let { token } = userLocal.get();
  const { campaign_id, sale_id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [popUpGift, setPopupGift] = useState("no");
  const [spinRemain, setSpinRemain] = useState<number>(0);
  const [lineId, setLineId] = useState();
  const [gift, setGift] = useState<any>();
  const [game, setGame] = useState<any>();
  const [draw, setDraw] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState("");
  const navigation = useNavigate();
  ///////////// HELPER FUNCTION /////////////

  const shuffle = (array: any) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const handleCloseError = () => {
    navigation("/games");
  };
  ///////////// SETUP /////////////

  const [cardList, setCardList] = useState(
    shuffle(cards).map((name: string, index: number) => {
      return {
        id: index,
        name: name,
        flipped: false,
        matched: false,
      };
    })
  );

  const [flippedCards, setFlippedCards] = useState<any>([]);
  const [gameOver, setGameOver] = useState(false);

  ///////////// GAME LOGIC /////////////

  const handleClick = (name: string, index: number) => {
    let currentCard = {
      name,
      index,
    };

    //update card is flipped
    let updateCards = cardList.map((card: any) => {
      if (card.id === index) {
        card.flipped = true;
      }
      return card;
    });
    let updateFlipped = flippedCards;
    updateFlipped.push(currentCard);
    setFlippedCards(updateFlipped);
    setCardList(updateCards);

    //if 2 cards are flipped, check if they are a match
    if (flippedCards.length === 2) {
      setTimeout(() => {
        check();
      }, 750);
    }
  };
  const runWheel = () => {
    console.log("ấn vào quay");
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    console.log(data);
    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log("trả data:", res);
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        setSpinRemain(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.error(err);
      });
  };
  const check = () => {
    let updateCards = cardList;
    if (
      flippedCards[0].name === flippedCards[1].name &&
      flippedCards[0].index !== flippedCards[1].index
    ) {
      updateCards[flippedCards[0].index].matched = true;
      updateCards[flippedCards[1].index].matched = true;
      isGameOver();
      runWheel();
      setIsOpenPopup(true);
      setStop(60000);
    } else {
      updateCards[flippedCards[0].index].flipped = false;
      updateCards[flippedCards[1].index].flipped = false;
    }
    setCardList(updateCards);
    setFlippedCards([]);
  };

  const isGameOver = () => {
    let done = false;
    cardList.forEach((card: any) => {
      if (!card.matched) done = false;
    });
    setGameOver(done);
  };

  ///////////// RESTART - REDO SETUP /////////////

  const restartGame = () => {
    setCardList(
      shuffle(cards).map((name: string, index: number) => {
        return {
          id: index,
          name: name,
          flipped: false,
          matched: false,
        };
      })
    );

    setFlippedCards([]);
    setGameOver(false);
  };

  ///////////// DISPLAY /////////////

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [stop, setStop] = useState(1000);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, stop);
    return () => {
      clearInterval(interval);
    };
  });
  // useEffect(() => {
  //   getCalculator(sale_id, campaign_id);
  // }, [isStatus ==="change"]);
  const handleClose = () => {
    // setMinutes(2);
    // setSeconds(0);
    const luot_quay = localStorage.getItem(LUOT_QUAY);
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      setStop(1000);
      setIsStatus("change");
      setIsOpenPopup(false);
      getCalculator(sale_id, campaign_id);
    }
  };

  useEffect(() => {
    setAuthorization(token);
    setLoading(true);
    getCalculator(sale_id, campaign_id);
  }, []);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi");
        } else {
          setLoading(false);
          setGame(res.lucky_draw_list.game_data);
          setPopupGift("no");
          console.log(res);
          setSpinRemain(res.remaining_draw);
          const countGiftWin = res.total_draw - res.remaining_draw;
          // console.log(countGiftWin);
          const giftWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          const giftDraw = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          const lstDraw = giftDraw.map((res: any) => res.gift_square_image_url);
          const draw = lstDraw.flatMap((item: any) => [item, item]);
          const a = shuffle(draw).map((name: string, index: number) => {
            return {
              id: index,
              name: name,
              flipped: false,
              matched: false,
            };
          });
          setCardList(a);
          // console.log(a);
          // console.log(giftWin);
          setGift(giftWin);
          setLineId(giftWin?.line_id);
          localStorage.setItem(
            "LIST_LUCKY_DRAW",
            JSON.stringify(res.lucky_draw_list.list_lucky_draw)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(giftWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  if (isError) {
    return (
      <PopupError
        labelBtn="Đồng ý"
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    );
  }
  return (
    <Fragment>
      <div className="relative top-0">
        <div className="relative">
          <img src={game?.background_image_url} />
        </div>

        <div className="absolute top-5 w-full">
          <div className="flex justify-center">
            <img src={game?.title_image_url} className="w-10/12" />
          </div>
          <div className="game-board grid grid-cols-2 gap-5 px-16 relative top-3 w-full">
            {!gameOver &&
              cardList.map((card: any, index: number) => (
                <Card
                  key={index}
                  id={index}
                  name={card.name}
                  flipped={card.flipped}
                  matched={card.matched}
                  clicked={flippedCards.length === 2 ? () => {} : handleClick}
                />
              ))}
            {gameOver && <GameOver restartGame={restartGame} />}
          </div>
          <div className="relative top-10 w-full">
            <div className="text-white relative left-1/2 -translate-x-1/2 w-full">
              <div className="flex justify-center relative">
                <img src={BUTTON} />
                <div className="absolute top-1/2 -translate-y-1/2 text-[#2D4E68]">
                  <span className="countdown-text text-[#2D4E68] font-semibold-so">
                    {" "}
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        &nbsp;{minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <button
                        disabled={seconds > 0 || minutes > 0}
                        className=" text-[#2D4E68] mx-1.5"
                        // onClick={resendOTP}
                      >
                        Hết thời gian
                      </button>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupOverlay isShow={isOpenPopup}>
          <div className="relative z-50 px-5">
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <div className="w-full absolute -top-1/2">
                    <img
                      src={BG_POPUP_FLIPCARD}
                      alt="Avatar"
                      className="w-full relative"
                      loading="lazy"
                    />
                    <div
                      className="absolute top-0 right-0"
                      onClick={handleClose}
                    >
                      <img src={CLOSE} />
                    </div>
                    <div className="w-full absolute top-16 left-1/2 -translate-x-1/2 gift-image text-white">
                      <div className="leading-8 font-semibold-so text-[24px] w-full relative top-0">
                        <div className="px-16">CHÚC MỪNG BẠN ĐÃ TRÚNG</div>
                        <div className="w-full">
                          <img
                            src={gift?.gift_reward_image_url}
                            alt="Image"
                            className="w-5/12 relative left-1/2 -translate-x-1/2"
                          />
                        </div>
                        <div className="mt-3 font-semibold-so text-[20px]">
                          {gift?.gift_name}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: gift?.conditions_receiving_prize,
                          }}
                          className="mt-1 px-10 text-[13px] leading-5 font-regular-so w-full"
                        ></div>
                        <div className="relative left-1/2 -translate-x-1/2 text-white">
                          <button
                            onClick={handleClose}
                            className="w-36 py-3 leading-5 rounded-xl bg-[#1D86FA] px-2 border-buttongame"
                          >
                            {spinRemain > 0 ? "Lật tiếp" : "Hết lượt quay"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flip-card-back">
                  <div className="w-full absolute -top-1/2">
                    <img
                      src={game?.card_image_url}
                      alt="Avatar"
                      className="w-full"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopupOverlay>
      </div>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </Fragment>
  );
};

export default GameBoard;
