import { useNavigate } from "react-router-dom";
import { ListBrands } from "../../models/HomePage";
export default function ItemBrand({
  category_image_url,
  category_name,
  category_id,
}: ListBrands) {
  const navigation = useNavigate();
  const handleRedictLstGame = () => {
    navigation(`/list-game/brand/${category_id}`);
    // navigation("/list-game/chanel_id");
  };
  return (
    <button
      className="col-span-1 pt-5 pb-2 item-gift rounded-3xl"
      onClick={handleRedictLstGame}
    >
      <div className="flex justify-center">
        <img
          src={category_image_url}
          loading="lazy"
          width={"40%"}
          alt="Image"
          className="rounded-xl"
        />
      </div>
      <div className="mt-2 font-bold-so">{category_name}</div>
    </button>
  );
}
