export default function Skelaton() {
  return (
    <div className="col-span-1 before:via-white/20 before:animate-pulse animate-pulse dark:divide-gray-700 dark:border-gray-700">
      <div className="rounded-2xl mt-3 mr-2">
        <div className="bg-[#fdfdfd] rounded-t-2xl">
          <div className="flex justify-center"></div>
        </div>
        <div className="bg-[#eeeeee] rounded-2xl h-48 pb-2 relative">
          <div className="p-1">
            <div className=" bg-[#d6d6d6] w-full h-24 rounded-2xl flex justify-center items-center">
              <svg
                className="w-10 h-10 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 20"
              >
                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
              </svg>
            </div>
          </div>
          <div className="font-semobold-so px-1 text-reponsive min-h-8">
            <div className="w-11/12 h-5 bg-[#d6d6d6] rounded-lg mt-1"></div>
            <div className="w-9/12 h-5 bg-[#d6d6d6] rounded-lg mt-1"></div>
            <div className="w-7/12 h-5 bg-[#d6d6d6] rounded-lg mt-1"></div>
          </div>
          <div className="flex items-start">
            <div className="col-span-2">
              <div></div>
            </div>
            <div className="col-span-10 pl-1">
              <div className="text-[#4E9ABB] ">
                <div className="text-[20px] font-semibold-so h-6  flex items-start ">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
