import { useEffect, useState } from "react";
import TEN_LUA from "../../assets/image/Popup/countdown.png";
import PHONE from "../../assets/image/Popup/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate, useParams } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import PopupGiftSubmitReceipt from "./SubmitReceipt/PopupGiftSubmitReceipt";
import { ListReceipt } from "../../models/GameModel";
import PopupError from "./PopupError";
import PopupConfirm from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirm";

export default function CountDown() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  // const { sale_id } = useParams();
  const sale_id = JSON.parse(localStorage.getItem("SALE_ID") || "{}");
  const navigation = useNavigate();
  console.log(sale_id);
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (e: any) => {
    setOtp(e);
  };
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [messError, setMessError] = useState("");
  const [isConfirm, setConfirm] = useState(false);
  const [listReceipt, setListReceipt] = useState<ListReceipt | undefined>(
    undefined
  );
  const [disableRedirect, setDisableRedirect] = useState(false);
  const [secondCount, setSecondCount] = useState(60);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (count === 1) {
            console.log("hello: ", count);
            setMinutes(0);
            setSeconds(0);
            setSecondCount(0);
            clearInterval(interval);
          } else if (count === 0) {
            console.log("hello");
            setMinutes(1);
            setSeconds(0);
            setSecondCount(60);
            // clearInterval(interval);
            setCount(count + 1);
          }
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    if (
      seconds === 10 ||
      seconds === 20 ||
      seconds === 30 ||
      seconds === 40 ||
      seconds === 50 ||
      seconds === 59
    ) {
      console.log(seconds);
      homeServices
        .RecallSubmitReceiptApi(sale_id as string)
        .then((res: any) => {
          console.log(res);
          if (res?.auto) {
            setConfirm(true);
            setMinutes(0);
            setSeconds(0);
            setListReceipt(res);
            setDisableRedirect(true);
            clearInterval(interval);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessError(err);
          setIsError(true);
        });
    }
    return () => {
      clearInterval(interval);
    };
  });
  const statusConfirm = localStorage.getItem("STATUS_CONFIRM");
  useEffect(() => {
    if (statusConfirm === "TRUE") {
      navigation("/");
    }
  }, []);

  const renderTime = ({ remainingTime }: any) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0 && count === 1) {
      if (!isConfirm) {
        navigation("/bill-invalid");
      }
      return (
        <div className="timer text-[#4E9ABB] font-semibold-so text-[20px]">
          Hết giờ ...
        </div>
      );
    }
    return (
      <div className="countdown-text text-[#4E9ABB] text-[36px] font-bold-so flex justify-center">{`${minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`}</div>
    );
  };
  const [checkPush, setCheckPush] = useState(false);
  const handleUrgent = () => {
    setCheckPush(true);
    if (!checkPush) {
      homeServices
        .PushReviewReceiptApi(sale_id !== undefined ? sale_id : ("" as string))
        .then((res: any) => {
          navigation("/urgent-page");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleClose = () => {
    setIsError(false);
    navigation("/");
  };
  return (
    <div className="bg-coundown min-h-screen relative">
      <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
        <div className="font-bold-so text-[#4E9ABB] text-center flex">
          Vui lòng đợi {count === 1 && "thêm"}
        </div>
        <div
          className={`${count === 1 ? "mt-0" : "mt-10"} flex justify-center `}
        >
          <img src={TEN_LUA} className="w-10/12" />
        </div>
        <div className="font-regular-so text-[#424242] text-[14px] text-center px-10">
          {count === 0
            ? "Mong quý khách thông cảm, hóa đơn của bạn đang được hệ thống xử lý"
            : "Mong quý khách thông cảm, hệ thống cần thêm thời gian để đọc hóa đơn"}
        </div>
        <div className="mt-16">
          <CountdownCircleTimer
            isPlaying
            duration={secondCount}
            colors={["#4E9ABB", "#4E9ABB", "#4E9ABB", "#bdbdbd"]}
            colorsTime={[secondCount, 6, 3, 0]}
            onComplete={() => ({ shouldRepeat: true, delay: 1 })}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        {count === 1 && (
          <div className="mt-10">
            <button
              onClick={() => {
                handleUrgent();
              }}
              className={`${
                checkPush ? "bg-[#bdbdbd]" : "bg-[#5BC2DC]"
              }  w-60 shadow-item text-[#FFFFFF] font-semibold-mon py-[20px] text-center rounded-[15px] border-first`}
            >
              Ưu tiên duyệt
            </button>
          </div>
        )}

        <div className="flex items-center mt-20">
          <div>
            <img src={PHONE} />
          </div>
          <div className="text-[#2D4E68] font-light-so ml-2">
            Liên hệ Hotline{" "}
            <span className="font-semibold-so">
              <a href={`tel:${hotline}`}>{hotline}</a>
            </span>
          </div>
        </div>
      </div>
      <PopupError
        isShow={isError}
        messError={messError}
        handleClose={handleClose}
        labelBtn="Đồng ý"
      />
      {listReceipt && (
        <PopupConfirm
          isShow={isConfirm}
          listReceipt={listReceipt}
          setConfirm={setConfirm}
        />
      )}
      <ContactPhoneZalo />
    </div>
  );
}
