import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import { BACK_GLOBAL } from "../../services/localService/localService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import { City, District, Ward } from "../../models/LoginModel";
import { DataRegister } from "../../models/ListReceiveCustomer";
import CALENDAR from "../../assets/image/Profile/calendar.png";
import DOWN from "../../assets/image/Profile/down.png";
import PopupTerm from "../../components/Popup/PopupTerm";

export default function Register1Component({
  goToPrevious,
  dataRegister,
  title,
  labelButton,
  handleBack,
  handleNext,
  required,
  consent,
  updateAfter,
  isLoading,
}: {
  goToPrevious: any;
  dataRegister: DataRegister;
  title: string;
  labelButton: string;
  handleBack: () => void;
  required: boolean;
  consent: boolean;
  handleNext: any;
  updateAfter: boolean;
  isLoading: boolean;
}) {
  console.log(dataRegister);
  const [isShowPopup, setShowPopup] = useState(false);

  const navigation = useNavigate();
  // const { phone } = useParams();
  const phone = localStorage.getItem("PHONE");
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });

  const [city, setCity] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [ward, setWard] = useState<any>([]);
  useEffect(() => {
    getCity();
  }, []);
  const onSubmit = (data: any) => {
    console.log(data);

    const params = {
      phone: phone,
    };
    const merge = Object.assign(data, params);
    console.log(merge);

    goToPrevious(merge);
  };

  const {
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  useEffect(() => {
    if (dataRegister) {
      getCity();
      getDistrict(dataRegister.city_id ? dataRegister.city_id : "");
      getWard(dataRegister.district_id ? dataRegister.district_id : "");
      setValue("name", dataRegister.name);
      setValue("street", dataRegister.street || "");
      setValue("birthday", dataRegister.birthday);
      setValue("gender", dataRegister.gender);
      setValue("phone", dataRegister.phone);
      setValue("email", dataRegister.email || "");
    }
  }, [dataRegister]);

  const getCity = () => {
    LoginServices.GetCityApi()
      .then((res) => {
        setCity(res);
        setValue("city_id", dataRegister?.city_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDistrict = (a: string) => {
    LoginServices.GetDistrictApi(a)
      .then((res: any) => {
        setDistrict(res);
        setValue("district_id", dataRegister?.district_id);
      })
      .catch((err) => {
        setValue("district_id", "");
        console.log(err);
      });
  };
  const getWard = (a: string) => {
    LoginServices.GetWardApi(a)
      .then((res: any) => {
        setWard(res);
        setValue("ward_id", dataRegister?.ward_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [name, setName] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<string>("");
  const [errorCheck, setErrorCheck] = useState<string>("");
  console.log(name);
  const handleUpdateAfter = () => {
    if (name && check) {
      handleNext(name);
    } else if (name === "" && check === false) {
      setErrorName("Vui lòng nhập số điện thoại");
      setErrorCheck("Vui lòng nhập chọn tôi đồng ý");
    } else if (name === "") {
      setErrorName("Vui lòng nhập số điện thoại");
    } else if (check === false) {
      setErrorCheck("Vui lòng nhập chọn tôi đồng ý");
    }
  };
  const checkPrize = () => {
    setShowPopup(true);
  };
  return (
    <div>
      <HeaderFrame
        footer={FOOTER}
        title={title}
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-10 px-3">
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Họ và tên <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập Họ và tên"
                  className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                  {...register("name", {
                    required: "Không được để trống",
                    onChange: (e) => {
                      setName(e.target.value);
                    },
                  })}
                />
              </div>
              <div className="w-full text-center text-[red] mt-1">
                {name ? "" : errorName}
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="grid grid-cols-5 gap-2">
                <div className="col-span-3">
                  <label
                    className="text-[#5BC2DC] font-semibold-so"
                    htmlFor="birthday"
                  >
                    Sinh nhật <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <label
                    className="border-itemlogin mt-1 flex justify-between w-full items-center relative"
                    htmlFor="birthday"
                  >
                    <input
                      type="date"
                      id="birthday"
                      {...register("birthday", {
                        required: required ? "Không được để trống" : required,
                      })}
                      className="text-left px-1 flex items-center justify-between text-[14px] font-light-so w-full pl-2 h-[50px] rounded-2xl input-hidden focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="absolute right-0">
                      <img src={CALENDAR} className="mr-1" />
                    </div>
                  </label>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="birthday"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Giới tính <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="day"
                      {...register("gender", {
                        required: required ? "Không được để trống" : required,
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Giới tính
                      </option>
                      <option value="male">Nam</option>{" "}
                      <option value="female">Nữ</option>
                      <option value="other">Khác</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">Email</label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập email"
                  className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                  {...register("email", {
                    // required: required ? "Không được để trống" : required,
                  })}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Nơi ở: Tỉnh/Thành <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="border-itemlogin mt-1 relative">
                <select
                  id="province"
                  {...register("city_id", {
                    required: required ? "Không được để trống" : required,
                    onChange: (a) => {
                      getDistrict(a.target.value);
                    },
                  })}
                  className="rounded-2xl px-3 w-full text-left  text-[14px] font-light-so py-[14px] input-hidden"
                >
                  <option value="" selected key={""}>
                    Tỉnh/Thành
                  </option>
                  {city?.map((res: City) => (
                    <option value={res.city_id} key={res.city_id}>
                      {res.city}
                    </option>
                  ))}
                </select>
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                  <img src={DOWN} className="mr-2" />
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="city_id"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Quận/Huyện
                  </label>
                  <div className="border-itemlogin mt-1 relative text-[14px] font-light-so">
                    <select
                      id="district"
                      {...register("district_id", {
                        // required: required ? "Không được để trống" : required,
                        onChange: (a) => {
                          getWard(a.target.value);
                        },
                      })}
                      className="px-3 w-full text-left  text-[14px] py-[14px] input-hidden rounded-2xl"
                    >
                      <option value="" selected key={""}>
                        Quận/Huyện
                      </option>
                      {district?.map((res: District) => (
                        <option value={res.district_id} key={res.district_id}>
                          {res.district}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="district_id"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Phường/Xã
                  </label>
                  <div className="border-itemlogin mt-1 relative text-[14px] font-light-so">
                    <select
                      id="ward"
                      {...register("ward_id", {
                        // required: required ? "Không được để trống" : required,
                      })}
                      className="rounded-2xl px-3 w-full text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected key={""}>
                        Phường/Xã
                      </option>
                      {ward?.map((res: Ward) => (
                        <option value={res.ward_id} key={res.ward_id}>
                          {res.ward}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="ward_id"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">Địa chỉ</label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập thông tin địa chỉ"
                  className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                  {...register("street", {
                    // required: required ? "Không được để trống" : required,
                  })}
                />
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="street"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            {consent && (
              <div className="mt-5">
                <div>
                  <input
                    type="checkbox"
                    placeholder=""
                    {...register("consent", {
                      required: "Vui lòng đồng ý điều khoản",
                      onChange: (e) => {
                        setCheck(!check);
                      },
                    })}
                  ></input>
                  <span className="font-light-so text-[14px] ml-1">
                    Tôi đã đọc{" "}
                    <span
                      className="font-medium-so text-[#4E9ABB]"
                      onClick={() => checkPrize()}
                    >
                      {" "}
                      Điều khoản sử dụng
                    </span>{" "}
                    và đồng ý với nội dung này. 
                  </span>
                </div>
                <div className="text-[red] text-center font-light-so">
                  {!check ? errorCheck : ""}
                </div>
                <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                  <ErrorMessage
                    errors={errors}
                    name="consent"
                    render={({ messages }) => {
                      return messages
                        ? Object.entries(messages).map(([type, message]) => (
                            <p
                              key={type}
                              className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                            >
                              {message}
                            </p>
                          ))
                        : null;
                    }}
                  />
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-around">
              <div className="flex justify-center">
                {updateAfter && (
                  <>
                    {isLoading ? (
                      <div className="flex justify-center mr-3">
                        <div className="bg-[#2D4E68] flex justify-center w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                          <LoadingButton />
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center mr-3">
                        <button
                          className="bg-[#2D4E68] flex justify-center w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white"
                          // onClick={() => handleUpdateAfter()}
                        >
                          Cập nhật sau
                        </button>
                      </div>
                    )}
                  </>
                )}
                {isLoading ? (
                  <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[12px] text-[14px] rounded-lg text-white">
                    <LoadingButton />
                  </div>
                ) : (
                  <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                    {labelButton}
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="mt-3 mb-10 relative z-50 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline} </a>
            </span>
            để được hỗ trợ
          </div>
        </div>
      </HeaderFrame>
      <PopupTerm isShowPopup={isShowPopup} setShowPopup={setShowPopup} />
    </div>
  );
}
