import BACK_HEADER from "../../assets/image/Header/back-header.png";
import { Header } from "../../models/HomePage";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export default function HeaderBack(header: Header) {
  const naviagte = useNavigate();
  const handleBack = () => {
    naviagte(header.navigate);
  };
  return (
    <div className="relative py-5">
      <div className="flex justify-between items-center">
        <button aria-label="buy product" onClick={handleBack}>
          <img
            src={header.imageBack}
            loading="lazy"
            width={40}
            sizes="32px"
            alt=""
          />
        </button>
        <div className="font-semibold-so text-[20px] text-[#2D4E68]">
          {header.name}
        </div>
        <div>
          <img
            src={header.img}
            loading="lazy"
            width={0}
            height={0}
            sizes="32px"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
