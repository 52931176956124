export default function BackgroundGame({
  img,
  children,
}: {
  img: any;
  children: React.ReactNode;
}) {
  return (
    <div className="relative top-0">
      <img src={img} loading="lazy" />
      {children}
    </div>
  );
}
