import PopupLogin from "../../popup/PopupLogin";

export default function PopupError({
  isShow,
  messError,
  handleClose,
  labelBtn,
}: {
  isShow: boolean;
  messError: string;
  handleClose: () => void;
  labelBtn: string;
}) {
  return (
    <div>
      <PopupLogin isShow={isShow}>
        <div className="px-5 pb-5">
          <div>
            {" "}
            <div className="">
              <div className="text-[24px] text-[#2D4E68] font-semibold-so pt-5 uppercase">
                Thông báo
              </div>
            </div>
          </div>
          <div className="mt-2">{messError}</div>
          <div>
            <button
              onClick={handleClose}
              className="bg-[#5BC2DC] w-40 px-8 mt-5 font-semibold-so py-3 text-[14px] rounded-lg text-white"
            >
              {labelBtn}
            </button>
          </div>
        </div>
      </PopupLogin>
    </div>
  );
}
